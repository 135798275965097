// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  companyName: 'Remax',
  node: 'ar',
  apiUrl: 'https://api-ar.redremax.com/remaxweb-ar/api/',
  host: 'https://www.remax.com.ar',
  googleAPIKey: 'AIzaSyDhAGtl9rk1EzL5h1GScU6iR7LjyrjbqHA',
  googleAPISecret: 'LannNXKFgQd97PJEyFqlms7wurU=',
  imageBucket: 'prod-remax-web-assets',
  imageCloudfront: 'https://d1acdg20u0pmxj.cloudfront.net',
  sitemapUrl: 'https://prod-ar-remax-web-assets.s3.amazonaws.com',
  robotUrl: 'https://prod-ar-remax-web-assets.s3.amazonaws.com/robots-ar.txt',
  imageBucketResizeUrl: 'https://d1acdg20u0pmxj.cloudfront.net/',
  testUser: 'Xzjn6gXv3g',
  testPass: '9WJeBNLsut',
  cognito: {
    aws_user_pools_id: 'us-east-1_c9ck5QjKo',
    aws_user_pools_web_client_id: '5inpctdh7qpqebibo48bsvuuqi',
    domain: 'amplifydomain-prodar.auth.us-east-1.amazoncognito.com',
    redirectSignIn: 'https://www.remax.com.ar/',
    redirectSignOut: 'https://www.remax.com.ar/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
